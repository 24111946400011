import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import KinstaLogo from './images/kinsta_logo.png';

// Constants moved to the top for better visibility and maintenance
const KINSTA_API_URL = process.env.REACT_APP_KINSTA_API_URL || 'https://api.kinsta.com/v2';
const CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const MAX_RETRIES = 3;
const RETRY_DELAY = 5000; // 5 seconds

// Custom hook for managing Kinsta API requests
const useKinstaAPI = () => {
    const queueRef = useRef([]);
    const processingRef = useRef(false);
    const cacheRef = useRef({});
    const totalRequestsRef = useRef(0);
    const completedRequestsRef = useRef(0);

    const [progress, setProgress] = useState(0);
    const [currentOperation, setCurrentOperation] = useState('');

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const cacheData = useCallback((key, data) => {
        cacheRef.current[key] = {
            data,
            timestamp: Date.now()
        };
        localStorage.setItem('kinstaCache', JSON.stringify(cacheRef.current));
    }, []);

    const getCachedData = useCallback((key) => {
        const cachedItem = cacheRef.current[key];
        if (cachedItem && (Date.now() - cachedItem.timestamp) < CACHE_EXPIRATION) {
            return cachedItem.data;
        }
        return null;
    }, []);

    const updateProgress = useCallback(() => {
        const progressPercentage = Math.round((completedRequestsRef.current / totalRequestsRef.current) * 100);
        setProgress(progressPercentage);
    }, []);

    const fetchWithRetry = useCallback(async (url, options, retries = 0) => {
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            if (retries < MAX_RETRIES) {
                console.log(`Retrying fetch (${retries + 1}/${MAX_RETRIES})...`);
                await delay(RETRY_DELAY);
                return fetchWithRetry(url, options, retries + 1);
            } else {
                throw error;
            }
        }
    }, []);

    const queuedFetch = useCallback((url, options) => {
        return new Promise((resolve, reject) => {
            const cachedData = getCachedData(url);
            if (cachedData) {
                completedRequestsRef.current++;
                updateProgress();
                resolve(cachedData);
                return;
            }

            queueRef.current.push({url, options, resolve, reject});
            totalRequestsRef.current++;
            processQueue();
        });
    }, [getCachedData, updateProgress]);

    const processQueue = useCallback(async () => {
        if (processingRef.current || queueRef.current.length === 0) return;
        processingRef.current = true;

        while (queueRef.current.length > 0) {
            const {url, options, resolve, reject} = queueRef.current.shift();
            try {
                await delay(1000); // Wait 1 second between requests
                setCurrentOperation(`Fetching data from ${new URL(url).pathname}`);
                const data = await fetchWithRetry(url, options);
                cacheData(url, data);
                completedRequestsRef.current++;
                updateProgress();
                resolve(data);
            } catch (error) {
                console.error('Error in fetch:', error);
                reject(error);
            }
        }

        processingRef.current = false;
        setCurrentOperation('');
    }, [cacheData, fetchWithRetry, updateProgress]);

    return {queuedFetch, progress, currentOperation};
};

// Custom hook for managing plugin data
const usePluginData = (kinstaAPI) => {
    const [plugins, setPlugins] = useState([]);
    const [sites, setSites] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchSitesWithPluginData = useCallback(async () => {
        try {
            const query = new URLSearchParams({
                company: process.env.REACT_APP_KINSTA_COMPANY_ID,
            }).toString();

            const sitesData = await kinstaAPI.queuedFetch(`${KINSTA_API_URL}/sites?${query}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_KINSTA_API_KEY}`,
                },
            });

            if (!sitesData.company) {
                throw new Error('Unexpected API response structure');
            }

            const companySites = sitesData.company.sites;

            const sitesWithPlugin = await Promise.all(companySites.map(async (site) => {
                const siteId = site.id;

                const envData = await kinstaAPI.queuedFetch(`${KINSTA_API_URL}/sites/${siteId}/environments`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_KINSTA_API_KEY}`,
                    },
                });

                const environmentId = envData.site.environments[0].id;

                const pluginsData = await kinstaAPI.queuedFetch(
                    `${KINSTA_API_URL}/sites/environments/${environmentId}/plugins`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${process.env.REACT_APP_KINSTA_API_KEY}`,
                        },
                    }
                );

                return {
                    env_id: environmentId,
                    name: site.display_name,
                    plugins: pluginsData.environment.container_info,
                };
            }));

            return sitesWithPlugin;
        } catch (error) {
            console.error('Error fetching site data:', error);
            throw error;
        }
    }, [kinstaAPI]);

    const fetchAllSitesPlugins = useCallback(async () => {
        try {
            setIsLoading(true);
            setError(null);
            const sitesWithPluginData = await fetchSitesWithPluginData();

            const allPlugins = sitesWithPluginData.flatMap((site) =>
                site.plugins.wp_plugins.data.map((plugin) => plugin.name)
            );
            const uniquePlugins = [...new Set(allPlugins)];
            setPlugins(uniquePlugins);
            return sitesWithPluginData;
        } catch (error) {
            setError('Failed to fetch plugins. Please check your network connection and try again.');
            console.error('Fetch error:', error);
        } finally {
            setIsLoading(false);
        }
    }, [fetchSitesWithPluginData]);

    return {plugins, sites, setSites, isLoading, error, fetchAllSitesPlugins};
};

// Main App component
const App = () => {
    const [pluginName, setPluginName] = useState('');
    const kinstaAPI = useKinstaAPI();
    const {plugins, sites, setSites, isLoading, error, fetchAllSitesPlugins} = usePluginData(kinstaAPI);

    useEffect(() => {
        fetchAllSitesPlugins();
    }, [fetchAllSitesPlugins]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        if (!pluginName) return;

        const filteredSites = sites
            .filter((site) => {
                const sitePlugins = site.plugins.wp_plugins.data;
                return sitePlugins.some((plugin) => plugin.name.toLowerCase() === pluginName.toLowerCase());
            })
            .map((site) => {
                const {env_id, name} = site;
                const {version, status} = site.plugins.wp_plugins.data.find(
                    (plugin) => plugin.name.toLowerCase() === pluginName.toLowerCase()
                );
                return {env_id, name, version, status};
            });

        setSites(filteredSites);
    }, [pluginName, sites, setSites]);

    // Memoized components
    const ProgressBar = useMemo(() => {
        return (
            <div className="progress-container">
                <div className="progress-bar">
                    <div className="progress" style={{width: `${kinstaAPI.progress}%`}}></div>
                </div>
                <div className="progress-info">
                    <p>{kinstaAPI.progress}% Complete</p>
                    <p>{kinstaAPI.currentOperation}</p>
                </div>
            </div>
        );
    }, [kinstaAPI.progress, kinstaAPI.currentOperation]);

    const PluginSelector = useMemo(() => {
        return (
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label htmlFor="plugin-name">Plugin name</label>
                    <select
                        name="plugin-name"
                        id="plugin-name"
                        value={pluginName}
                        onChange={(e) => setPluginName(e.target.value.toLowerCase())}
                        disabled={isLoading}
                    >
                        {plugins.length > 0 ? (
                            <>
                                <option value="">Select a plugin</option>
                                {plugins.map((plugin) => (
                                    <option key={plugin} value={plugin.toLowerCase()}>
                                        {plugin}
                                    </option>
                                ))}
                            </>
                        ) : (
                            <option value="">Loading plugins...</option>
                        )}
                    </select>
                </div>
                <button className="btn" type="submit" disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Fetch sites with this plugin'}
                </button>
            </form>
        );
    }, [plugins, pluginName, isLoading, handleSubmit]);

    const SiteList = useMemo(() => {
        if (sites.length === 0) return null;

        return (
            <div className="display_container">
                <div className="site-list">
                    <div className="list-title">
                        <h3>Sites with {pluginName} plugin</h3>
                    </div>
                    <ul>
                        {sites.map((site) => (
                            <li key={site.env_id}>
                                <div className="info">
                                    <p>
                                        <b>Site Name:</b> {site.name}
                                    </p>
                                    <p>
                                        <b>Plugin Status:</b> {site.status}
                                    </p>
                                    <p>
                                        <b>Plugin Version:</b> {site.version}
                                    </p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }, [sites, pluginName]);

    return (
        <div className="container">
            <div className="title-section">
                <img src={KinstaLogo} className="logo" alt="Kinsta Logo"/>
                <h2>View your site's plugins</h2>
                <p>
                    Easily view plugins across all sites hosted with Kinsta using the
                    Kinsta API.
                </p>
            </div>
            <div className="info-section">
                <p>
                    This application allows you to retrieve a list of all sites within
                    your company that use a specific plugin.
                </p>
            </div>
            {isLoading && ProgressBar}
            <div className="form-section">
                {PluginSelector}
            </div>
            {error && (
                <div className="error">
                    <p>{error}</p>
                </div>
            )}
            {SiteList}
        </div>
    );
};

export default App;